<template>
  <banner text="Schüler-Portal" icon="graduation cap"/>

  <h2>Passwort zurücksetzen</h2>
  <div class="ui segment">
    <form class="ui form success error" v-on:submit.prevent="resetPassword"
          v-if="resetPasswordForm.token && resetPasswordForm.email">

      <template v-if="!resetPasswordSuccess">
        <div class="ui segment">
          Hallo {{ username }},<br/><br/>
          hier kannst du dein neues Passwort setzen.
          Bitte gib ein Passwort deiner Wahl mit mindestens 8 Zeichen ein.
        </div>

        <div v-bind:class="[passwordLengthError ? 'field error' : 'field']">
          <label>Passwort</label>
          <input type="password" required v-model="resetPasswordForm.password" placeholder="secret*123"/>
        </div>
        <div class="ui error message" v-if="passwordLengthError">
          <p>Das Passwort muss mindestens 8 Zeichen lang sein.</p>
        </div>

        <div v-bind:class="[passwordConfirmationError ? 'field error' : 'field']">
          <label>Passwort bestätigen</label>
          <input type="password" required v-model="resetPasswordForm.password_confirmation" placeholder="secret*123"/>
        </div>
        <div class="ui error message" v-if="passwordConfirmationError">
          <p>Die Bestätigung stimmt nicht mit dem Passwort überein.</p>
        </div>

        <button type="submit" class="ui green button">Neues Passwort setzen</button>
      </template>

      <div class="ui success message" v-if="resetPasswordSuccess">
        <p>Wir haben dein neues Passwort gesetzt, du kannst dich ab sofort damit einloggen.</p>
        <p>Du wirst in Kürze weitergeleitet...</p>
      </div>

    </form>

    <div class="ui red inverted segment" v-else>
      Du brauchst ein Token, um dein Passwort zurückzusetzen.<br>
      <router-link to="/">Hier</router-link>
      kannst du eine Email dafür anfordern.
    </div>
  </div>
</template>

<script>
import store from "../store";
import router from "../router";
import Banner from "../components/Banner";

export default {
  components: {Banner},
  data: function () {
    return {
      resetPasswordForm: {
        email: null,
        password: null,
        password_confirmation: null,
        token: null
      },
      username: null,
      resetPasswordSuccess: false,
      errors: null
    }
  },
  methods: {
    resetPassword: function () {
      store.dispatch('auth/resetPassword', this.resetPasswordForm)
          .then(() => {
            this.resetPasswordSuccess = true;
            setTimeout(() => {
              router.push('/login');
            }, 5000);
          })
          .catch((error) => {
            this.errors = error.response.data.errors;
          });
    }
  },
  computed: {
    passwordLengthError: function () {
      return this.errors && this.errors.password
          && this.errors.password.includes('The password must be at least 8 characters.');
    },
    passwordConfirmationError: function () {
      return this.errors && this.errors.password
          && this.errors.password.includes('The password confirmation does not match.');
    }
  },
  created() {
    this.username = this.$route.query.name;
    this.resetPasswordForm.email = this.$route.query.email;
    this.resetPasswordForm.token = this.$route.query.token;
  }
}
</script>
